import React from "react";
import { Link } from "react-router-dom";
import corpsureLandlordLogo from "./assets/corpsure-landlord.svg";

export default function Navbar() {
  return (
    <React.Fragment>
      <nav className="bg-[#323637] fixed w-full z-50 drop-shadow-2xl">
        <div className="container mx-auto max-w-[85vw]">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
              </button>
            </div>
            <Link
              to="/"
              className="flex flex-shrink-0 items-center hover:cursor-pointer hover:scale-105 duration-200"
              preventScrollReset={false}
            >
              <img
                src={corpsureLandlordLogo}
                className="w-auto h-12"
                alt="Corpsure Landlord Logo"
              />
            </Link>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
