import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "pages/home";
import GetQuote from "pages/registration-flow/1-get-quote";
import Protection from "pages/registration-flow/2-protection";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<GetQuote />} />
        <Route path="/protection" element={<Protection />} />
      </Routes>
    </BrowserRouter>
  );
}
