import React from "react";
import { BiSolidLock } from "react-icons/bi";
import { PiMagicWandFill } from "react-icons/pi";

export default function CoverCard({ selectedCover, handleSelectCover }) {
  return (
    <div className="mb-6">
      <h2 className="text-[#202020] text-[20px] font-semibold mb-2">Cover</h2>
      <div className="">
        <p className="text-[#576263] text-[16px] font-light mb-2">
          {/* Select landlord cover */}
        </p>
        <div className="flex flex-col md:flex-row gap-4">
          <div
            // onClick={() => handleSelectCover("landlord")}
            className={`${
              selectedCover === "landlord"
                ? "bg-[#2B87C8] text-white"
                : "bg-white text-[#576263] border border-[#CDD4D4]"
            } relative w-full md:max-w-[250px] p-5 rounded-xl hover:scale-105 hover:cursor-pointer transition duration-300 ease-in-out`}
          >
            <BiSolidLock className="absolute w-32 h-32 top-2 right-2 opacity-5" />
            <BiSolidLock className="w-10 h-10 m-0 mb-4" />
            <h4 className="text-[18px] font-semibold mb-2">Full House Cover</h4>
            <p
              className={`${
                selectedCover === "landlord"
                  ? "text-[#F1F1F1]"
                  : "text-[#808F90]"
              } text-[16px] mb-1`}
            >
              Insert Description
            </p>
            <p
              className={`${
                selectedCover === "landlord"
                  ? "text-[#F1F1F1]"
                  : "text-[#808F90]"
              } text-[13px]`}
            >
              Insert Description
            </p>
          </div>
          {/* <div
            onClick={() => handleSelectCover("customised")}
            className={`${
              selectedCover === "customised"
                ? "bg-[#2B87C8] text-white"
                : "bg-white text-[#576263] border border-[#CDD4D4]"
            } relative w-full md:max-w-[250px] p-5 rounded-xl hover:scale-105 hover:cursor-pointer transition duration-300 ease-in-out`}
          >
            <PiMagicWandFill className="absolute w-32 h-32 top-2 right-2 opacity-5" />
            <PiMagicWandFill className="w-10 h-10 m-0 mb-4" />
            <h4 className="text-[18px] font-semibold mb-2">Customised Cover</h4>
            <p
              className={`${
                selectedCover === "customised"
                  ? "text-[#F1F1F1]"
                  : "text-[#808F90]"
              } text-[16px] mb-1`}
            >
              Select your own covers
            </p>
            <p
              className={`${
                selectedCover === "customised"
                  ? "text-[#F1F1F1]"
                  : "text-[#808F90]"
              } text-[13px]`}
            >
              Customised levels of Cover to protect your investment. Choose
              Building & Contents Sum Insured & Excess. Options to include
              tenant covers.
            </p>
          </div> */}
        </div>
      </div>
      <div className="ml-1 my-2">
        <a href="#findout" className="text-[#2B87C8] text-[16px]">
          Find out more
        </a>
      </div>
    </div>
  );
}
