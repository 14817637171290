import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  IoList,
  IoInformationCircle,
  IoShieldCheckmarkSharp,
} from "react-icons/io5";
import { BsFillBuildingsFill } from "react-icons/bs";
import { MdWorkspacePremium } from "react-icons/md";

import Navbar from "components/navbar";
import StepperComponent from "components/stepper";
import Declaration from "./1-declaration";
import PropertyAndCover from "./2-propertyAndCover";
import AdditionalInfo from "./3-additionalInfo";
import Insured from "./4-insured";
import Premium from "./5-premium";
import axios from "axios";

export default function Protection() {
  const endDefaultDate = new Date();
  endDefaultDate.setMonth(endDefaultDate.getMonth() + 12);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(getStepFromURL());
  const [customerForm, setCustomerForm] = useState({
    get_a_quote: "domestic",
    declaration: false,
    insurance_start_date: new Date().toISOString().split("T")[0],
    insurance_end_date: endDefaultDate.toISOString().split("T")[0],
    insured_property_adress: "",
    cover: "landlord",
    typeOfProperty: "Residential",
    activeLeases: 1,
    rentFrequency: 0,
    claims: "no",
    currentInsurer: "QBE Insurance Group Limited",
    insure_building: "yes",
    tenants_default_or_rent: "default",
    is_contents_more_than: "no",
    replacementValue: "100000",
    constructionOfExternalWalls: "concrete",
    constructionOfRoof: "flat",
    buildingDate: 0,
    property_listed: "no",
    building_maintained: "no",
    property_for_sale: "no",
    property_scheduled_for_demolition: "no",
    property_undergoing_renovations: "no",
    require_mortgage: "no",
    property_managed_by_licensed_manager: "yes",
    property_part_of_national: "no",
    property_sublet: false,
    property_different_name: false,
    property_seperate_agreements: false,
    none_of_above: false,
    residential_tenancies_act_requirements: "no",
    many_lease_agreement_active: 0,
    property_occupied_by_tenant: "no",
    insured_type: "individual",
    insured_name: "",
    contact_method: "email",
    correspondence_email: "",
    correspondence_phone: "",
    contact_name: "",
    postal_address: "",
    terms_and_conditions: false,
    pay_now_pay_later: "pay_now",
  });

  // Define the steps in the stepper with corresponding icons
  const steps = [
    { name: "Declaration", icon: IoList },
    { name: "Property and Cover", icon: BsFillBuildingsFill },
    { name: "Additional Information", icon: IoInformationCircle },
    { name: "Insured", icon: IoShieldCheckmarkSharp },
    { name: "Premium", icon: MdWorkspacePremium },
  ];

  function getStepFromURL() {
    const queryParams = new URLSearchParams(location.search);
    const step = parseInt(queryParams.get("step"));
    return isNaN(step) ? 0 : step;
  }

  useEffect(() => {
    console.log("URL Changed: ", location.search);
    const stepFromURL = getStepFromURL();
    if (currentStep !== stepFromURL) {
      console.log("Updating currentStep to: ", stepFromURL);
      setCurrentStep(stepFromURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  function incrementStep() {
    console.log(customerForm);
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    navigate(`?step=${nextStep}`);
  }

  function decrementStep() {
    const prevStep = Math.max(currentStep - 1, 0);
    setCurrentStep(prevStep);
    navigate(`?step=${prevStep}`);
  }

  async function sendData() {
    setIsSaving(true);
    try {
      console.log("Sending data: ", customerForm);
      const response = await axios.post(
        "https://payload-3-0-alpha-demo-corpsure-test1.vercel.app/api/sensorcustomers",
        customerForm
      );
      alert("Form submitted successfully!");
      setIsSaving(false);
    } catch (e) {
      console.error("Error sending data:", e);
    }
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className="bg-[#F6F6F9] w-full min-h-screen overflow-hidden">
        <section className="container mx-auto my-28">
          <div className="max-w-[85vw] mx-auto">
            <div className="relative border-b-2 border-[#CDD4D4] mb-5">
              <h1 className="text-[#202020] text-[30px] font-semibold">
                Landlord Protection
              </h1>
            </div>
            <StepperComponent steps={steps} currentStep={currentStep} />
            {currentStep === 0 && (
              <Declaration
                incrementStep={incrementStep}
                decrementStep={decrementStep}
                customerForm={customerForm}
                setCustomerForm={setCustomerForm}
              />
            )}
            {currentStep === 1 && (
              <PropertyAndCover
                incrementStep={incrementStep}
                decrementStep={decrementStep}
                customerForm={customerForm}
                setCustomerForm={setCustomerForm}
              />
            )}
            {currentStep === 2 && (
              <AdditionalInfo
                incrementStep={incrementStep}
                decrementStep={decrementStep}
                customerForm={customerForm}
                setCustomerForm={setCustomerForm}
              />
            )}
            {currentStep === 3 && (
              <Insured
                incrementStep={incrementStep}
                decrementStep={decrementStep}
                customerForm={customerForm}
                setCustomerForm={setCustomerForm}
              />
            )}
            {currentStep === 4 && (
              <Premium
                incrementStep={incrementStep}
                decrementStep={decrementStep}
                customerForm={customerForm}
                setCustomerForm={setCustomerForm}
                sendData={sendData}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
              />
            )}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
