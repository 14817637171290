import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";

export default function Declaration({
  incrementStep,
  decrementStep,
  customerForm,
  setCustomerForm,
}) {
  const [isDeclarationChecked, setIsDeclarationChecked] = useState(false);
  const [showDeclarationError, setShowDeclarationError] = useState(false);
  const [isYes, setIsYes] = useState(null);

  return (
    <div className="max-w-[85vw] mx-auto">
      <div className="relative bg-white p-6 md:p-10 rounded-xl">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-[60%]">
            <div className=" flex flex-col justify-center mb-5">
              <h2 className="text-[#202020] text-[20px] font-semibold mb-5">
                Important Information
              </h2>
              <h3 className="text-[#333333] text-[16px] font-semibold mb-4">
                Duty to take reasonable care not to make a misrepresentation
              </h3>
              <p className="text-[#657375] text-[14px] mb-3">
                Before you enter into this contract of insurance, you have a
                duty to take reasonable care not to make a misrepresentation.
                You have a similar duty when you ask us to vary or extend the
                insurance, and when we offer to renew your insurance. This means
                that you need to take reasonable care to provide honest,
                accurate and complete answers to our questions. A
                misrepresentation includes a statement that is false, partially
                false, or which does not fairly reflect the truth.
              </p>
              <p className="text-[#657375] text-[14px] mb-3">
                When you ask us to vary or extend your insurance or before you
                renew your insurance, you need to take reasonable care to review
                any information that we provide to you for your confirmation and
                to tell us about any changes, if the information is no longer
                honest, accurate and complete.
              </p>
              <p className="text-[#657375] text-[14px] mb-3">
                If you are not sure of the answers to any of our questions, or
                whether the information you previously provided remains honest,
                accurate and complete, you should check it and find out. It is
                also important to understand that, in answering the questions
                and checking the information, you are answering for yourself and
                anyone else to whom the questions apply. If you are answering
                questions on behalf of anyone, we will treat your answers or
                representations as theirs.
              </p>
              <p className="text-[#657375] text-[14px] mb-3">
                As we use your answers to decide what insurance we will offer,
                to calculate your premium, and to assess any claim you make, it
                is essential that you contact us if you have any doubts.
              </p>
              <p className="text-[#657375] text-[14px] mb-3">
                If you do not take reasonable care in answering our questions,
                or to inform us of any changes, you may breach your duty. If
                that happens, your policy may be cancelled, or treated as if
                never existed, and any claim may be denied or not paid in full.
              </p>
              <p className="text-[#657375] text-[14px] mb-3">
                Whether you have taken reasonable care not to make a
                misrepresentation will be determined with regard to all relevant
                circumstances, including:
              </p>
              <div className="text-[#657375] text-[14px]">
                <ul className="list-outside list-disc pl-5">
                  <li>the product type and its target market;</li>
                  <li>
                    explanatory material or publicity produced or authorised by
                    us;
                  </li>
                  <li>
                    how clear, and how specific any questions we asked were and
                    how clearly we communicated to you the importance of
                    answering those questions, and the possible consequences of
                    failing to do so;
                  </li>
                  <li>whether or not an agent was acting for you;</li>
                  <li>
                    whether the contract was a new contract or was being
                    renewed, extended, varied or reinstated;
                  </li>
                  <li>
                    any particular characteristics or circumstances relating to
                    you which we are aware or ought reasonably to have been
                    aware.
                  </li>
                </ul>
              </div>
              <div className="flex flex-col items-start my-4">
                <div className="flex items-center">
                  <input
                    id="declaration"
                    type="checkbox"
                    checked={customerForm.declaration}
                    onChange={() => {
                      setCustomerForm((prevState) => ({
                        ...prevState,
                        declaration: !customerForm.declaration,
                        get_a_quote: localStorage.getItem("get_a_quote"),
                      }));
                      setShowDeclarationError(false);
                    }}
                    className={`w-5 h-5 bg-gray-100 rounded cursor-pointer ${
                      customerForm.declaration
                        ? "border-red-500"
                        : "text-[#2B87C8] border-gray-400"
                    } focus:ring-[#2B87C8]`}
                  />
                  <label
                    htmlFor="declaration"
                    className="ms-2 text-sm font-medium text-[#202020] text-[14px] cursor-pointer"
                  >
                    I have read and understand the above disclosure.
                  </label>
                </div>
                {showDeclarationError && (
                  <p className="text-red-500 text-[13px] mt-2 ml-7">
                    Please check the declaration before proceeding.
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-center">
              <h2 className="text-[#202020] text-[20px] font-semibold mb-3">
                Client Declaration
              </h2>
              <div className="text-[#657375] text-[14px]">
                <ul className="list-outside list-disc pl-5">
                  <li>
                    Have you ever had any type of insurance proposal declined or
                    any type of policy cancelled or renewal refused or had
                    special terms or conditions imposed by any insurer?
                  </li>
                  <li>
                    Have you been convicted of theft or fraud in the past 5
                    years?
                  </li>
                  <li>
                    How clear, and how specific any questions we asked were and
                    how clearly we communicated to you the importance of
                    answering those questions, and the possible consequences of
                    failing to do so;
                  </li>
                  <li>Have you been declared bankrupt in the past 5 years?</li>
                  <li>
                    Are you aware of any existing circumstances that may lead to
                    a claim under this policy?
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex gap-2 mt-5">
              <button
                onClick={() => setIsYes(false)}
                className={`text-white py-1 px-4 rounded-[3rem] ${
                  isYes === false ? "bg-[#2B87C8]" : "bg-[#657375]"
                }`}
              >
                No
              </button>
              <button
                onClick={() => setIsYes(true)}
                className={`text-white py-1 px-4 rounded-[3rem] ${
                  isYes === true ? "bg-[#2B87C8]" : "bg-[#657375]"
                }`}
              >
                Yes
              </button>
            </div>
          </div>
          <div className="w-full md:w-[40%] my-5 md:m-0">
            <div className="flex justify-end">
              <div className="bg-[#2B87C8] w-full md:w-[320px] text-white shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                <h4 className="text-[16px] font-semibold">
                  Landlord Protection
                </h4>
                <p className="text-[14px] font-light">Landlord Protection</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={() => {
              if (customerForm.declaration) {
                incrementStep();
              } else {
                console.log(customerForm);
                setShowDeclarationError(true);
              }
            }}
            className="flex bg-[#2B87C8] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
          >
            Next
            <FaArrowRight className="text-[14px]" />
          </button>
        </div>
      </div>
    </div>
  );
}
