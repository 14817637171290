import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";

export default function AdditionalInfo({
  incrementStep,
  decrementStep,
  address,
  startDate,
  endDate,
  customerForm,
  setCustomerForm,
}) {
  function handleBuildingMaintainedChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      building_maintained: e,
    }));
  }

  function handlePropertyForSaleChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      property_for_sale: e,
    }));
  }

  function handleScheduleForDemolitionChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      property_scheduled_for_demolition: e,
    }));
  }

  function handleUndergoingRenovationsChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      property_undergoing_renovations: e,
    }));
  }

  function handleRequireMortgageChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      require_mortgage: e,
    }));
  }

  function handleManagedByLicensedChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      property_managed_by_licensed_manager: e,
    }));
  }

  function handlePartOfNationalChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      property_part_of_national: e,
    }));
  }

  function handleTenanciesActChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      residential_tenancies_act_requirements: e,
    }));
  }

  function handleOccupiedByTenantChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      property_occupied_by_tenant: e,
    }));
  }

  return (
    <div className="max-w-[85vw] mx-auto">
      <div className="relative bg-white p-6 md:p-10 rounded-xl">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-[60%]">
            <div className="flex flex-col justify-center mb-5">
              <div className="mb-6">
                <h2 className="text-[#202020] text-[20px] font-semibold">
                  Additional Information
                </h2>
              </div>
              <div className="">
                <h2 className="text-[#202020] text-[18px] font-semibold mb-3">
                  Additional Property Information
                </h2>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    Is the building structurally sound and well maintained?
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleBuildingMaintainedChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.building_maintained === "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handleBuildingMaintainedChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.building_maintained === "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    Is the property for sale?
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handlePropertyForSaleChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_for_sale === "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handlePropertyForSaleChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_for_sale === "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    Is the property scheduled for demolition?
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleScheduleForDemolitionChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_scheduled_for_demolition === "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handleScheduleForDemolitionChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_scheduled_for_demolition === "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    Is your property currently undergoing renovations?
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleUndergoingRenovationsChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_undergoing_renovations === "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handleUndergoingRenovationsChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_undergoing_renovations === "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    Do you require a mortgagee or an interested party to be
                    noted?
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleRequireMortgageChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.require_mortgage === "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handleRequireMortgageChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.require_mortgage === "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
              <div className="">
                <h2 className="text-[#202020] text-[18px] font-semibold mb-3">
                  Tenancy Details
                </h2>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    Is the property managed by a licensed property manager?
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleManagedByLicensedChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_managed_by_licensed_manager ===
                        "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handleManagedByLicensedChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_managed_by_licensed_manager ===
                        "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    {`Is the property part of National Rent Affordability Scheme
                    (NRAS) or a government/community scheme?`}
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handlePartOfNationalChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_part_of_national === "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handlePartOfNationalChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_part_of_national === "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light mb-2">
                    Do any of the following apply?
                  </p>
                  <div className="flex items-center mb-1">
                    <input
                      id="sublet"
                      type="checkbox"
                      checked={customerForm.property_sublet}
                      onChange={() => {
                        setCustomerForm((prevState) => ({
                          ...prevState,
                          property_sublet: !customerForm.property_sublet,
                        }));
                      }}
                      className="w-5 h-5 bg-gray-100 rounded cursor-pointer text-[#2B87C8] border-gray-400 focus:ring-[#2B87C8]"
                    />
                    <label
                      htmlFor="sublet"
                      className="ms-2 text-sm font-medium text-[#576263] text-[14px] cursor-pointer"
                    >
                      The property is being sublet
                    </label>
                  </div>
                  <div className="flex items-center mb-1">
                    <input
                      id="differentName"
                      type="checkbox"
                      checked={customerForm.property_different_name}
                      onChange={() => {
                        setCustomerForm((prevState) => ({
                          ...prevState,
                          property_different_name:
                            !customerForm.property_different_name,
                        }));
                      }}
                      className="w-5 h-5 bg-gray-100 rounded cursor-pointer text-[#2B87C8] border-gray-400 focus:ring-[#2B87C8]"
                    />
                    <label
                      htmlFor="differentName"
                      className="ms-2 text-sm font-medium text-[#576263] text-[14px] cursor-pointer"
                    >
                      The lease agreement is in a different name to the
                      occupants residing in the property
                    </label>
                  </div>
                  <div className="flex items-center mb-1">
                    <input
                      id="fourOrMoreLeaseAgreements"
                      type="checkbox"
                      checked={customerForm.property_seperate_agreements}
                      onChange={() => {
                        setCustomerForm((prevState) => ({
                          ...prevState,
                          property_seperate_agreements:
                            !customerForm.property_seperate_agreements,
                        }));
                      }}
                      className="w-5 h-5 bg-gray-100 rounded cursor-pointer text-[#2B87C8] border-gray-400 focus:ring-[#2B87C8]"
                    />
                    <label
                      htmlFor="fourOrMoreLeaseAgreements"
                      className="ms-2 text-sm font-medium text-[#576263] text-[14px] cursor-pointer"
                    >
                      A total of 4 or more separate lease agreements for this
                      property has been in place in the last 12 months
                    </label>
                  </div>
                  <div className="flex items-center mb-1">
                    <input
                      id="noneOfTheAbove"
                      type="checkbox"
                      checked={customerForm.none_of_above}
                      onChange={() => {
                        setCustomerForm((prevState) => ({
                          ...prevState,
                          none_of_above: !customerForm.none_of_above,
                        }));
                      }}
                      className="w-5 h-5 bg-gray-100 rounded cursor-pointer text-[#2B87C8] border-gray-400 focus:ring-[#2B87C8]"
                    />
                    <label
                      htmlFor="noneOfTheAbove"
                      className="ms-2 text-sm font-medium text-[#576263] text-[14px] cursor-pointer"
                    >
                      None of the above
                    </label>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    {`Do/will all the leases in place meet the Residential
                    Tenancies Act requirements?`}
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleTenanciesActChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.residential_tenancies_act_requirements ===
                        "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handleTenanciesActChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.residential_tenancies_act_requirements ===
                        "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                <div className="mb-4">
                  <p className="text-[#576263] text-[16px] font-light">
                    Is the property currently occupied by a tenant?
                  </p>
                  <div className="flex gap-2 mt-2">
                    <button
                      onClick={() => handleOccupiedByTenantChange("no")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_occupied_by_tenant === "no"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      No
                    </button>
                    <button
                      onClick={() => handleOccupiedByTenantChange("yes")}
                      className={`text-white py-1 px-4 rounded-[3rem] ${
                        customerForm.property_occupied_by_tenant === "yes"
                          ? "bg-[#2B87C8]"
                          : "bg-[#657375]"
                      }`}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-[40%]">
            <div className="flex justify-start mb-4 md:mb-0 md:justify-end">
              <div className="flex flex-col gap-4">
                <div className="bg-[#2B87C8] w-full md:w-[320px] text-white shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                  <div>
                    <h4 className="text-[16px]">Landlord Protection</h4>
                    <p className="text-[14px] font-light">Value Plus</p>
                  </div>
                  <hr className="my-4" />
                  <div className="flex items-center gap-4 cursor-pointer">
                    <h4 className="text-[16px]">
                      Product Disclosure Statement
                    </h4>
                    <MdArrowOutward className="w-5 h-5" />
                  </div>
                  <hr className="my-4" />
                  <div>
                    <h4 className="text-[16px]">Estimated Premium</h4>
                    <h5 className="text-[32px] font-semibold pb-2">
                      {"$361.98"}
                    </h5>
                    <p className="text-[12px] font-light">
                      {"(Including GST and Government Charges)"}
                    </p>
                  </div>
                </div>
                <div className="bg-white w-full md:w-[320px] text-[#576263] border border-[#CDD4D4] shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                  <div className="mb-6">
                    <h4 className="text-[16px] font-semibold">
                      Property Address
                    </h4>
                    <p className="text-[14px] font-light">
                      {address || "Not specified."}
                    </p>
                  </div>
                  <div className="mb-6">
                    <h4 className="text-[16px] font-semibold">
                      Period of Insurance
                    </h4>
                    <p className="text-[14px] font-light">
                      {startDate && endDate
                        ? `${startDate} - ${endDate}`
                        : "Not specified."}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-[16px] font-semibold">Quote Ref</h4>
                    <p className="text-[14px] font-light">Q-588820</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-end mt-4">
          <button
            onClick={decrementStep}
            className="flex bg-[#657375] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
          >
            <FaArrowLeft className="text-[14px]" />
            Previous
          </button>
          <button
            onClick={incrementStep}
            className="flex bg-[#2B87C8] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
          >
            Next
            <FaArrowRight className="text-[14px]" />
          </button>
        </div>
      </div>
    </div>
  );
}
