import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";

import CoverCard from "../components/coverCard";
// import LandlordCover from "../components/landlordCover";
// import CustomisedCover from "../components/customisedCover";
import FullhouseCover from "../components/fullHouseCover";

export default function PropertyAndCover({
  incrementStep,
  decrementStep,
  customerForm,
  setCustomerForm,
}) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState("");
  const [address, setAddress] = useState("");
  const [selectedCover, setSelectedCover] = useState("landlord");
  const [showError, setShowError] = useState(false);

  function handleStartDateChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      insurance_start_date: e.target.value,
    }));
  }

  function handleEndDateChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      insurance_end_date: e.target.value,
    }));
  }

  function handleAddressChange(e) {
    setCustomerForm((prevState) => ({
      ...prevState,
      insured_property_adress: e.target.value,
    }));
    setShowError(false);
  }

  function handleSelectCover(value) {
    console.log(value);
    setCustomerForm((prevState) => ({
      ...prevState,
      cover: value,
    }));
  }

  return (
    <div className="max-w-[85vw] mx-auto">
      <div className="relative bg-white p-6 md:p-10 rounded-xl">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-[60%]">
            <div className="flex flex-col justify-center mb-5">
              <div className="mb-6">
                <h2 className="text-[#202020] text-[20px] font-semibold mb-2">
                  Property and Cover
                </h2>
                <div className="flex flex-col md:flex-row gap-4 text-[#576263] text-[16px]">
                  <div className="mb-5 md:mb-0">
                    <label htmlFor="insuranceStartDate" className="block mb-2">
                      Insurance Start Date
                    </label>
                    <input
                      type="date"
                      id="insuranceStartDate"
                      name="insuranceStartDate"
                      // placeholder="01-01-2024"
                      value={customerForm.insurance_start_date}
                      onChange={handleStartDateChange}
                      className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-full"
                    />
                  </div>
                  <div>
                    <label htmlFor="insuranceEndDate" className="block mb-2">
                      Insurance End Date
                    </label>
                    <input
                      type="date"
                      id="insuranceEndDate"
                      name="insuranceEndDate"
                      placeholder="01-01-2024"
                      value={customerForm.insurance_end_date}
                      onChange={handleEndDateChange}
                      className="form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border border-gray-300 w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="mb-6">
                <h2 className="text-[#202020] text-[20px] font-semibold mb-2">
                  Insured Property Address
                </h2>
                <div className="mb-5 md:mb-0 text-[#576263] text-[16px]">
                  <label htmlFor="insuranceStartDate" className="block mb-2">
                    Address
                  </label>
                  <textarea
                    type="text"
                    id="insuredAddress"
                    name="insuredAddress"
                    value={customerForm.insured_property_adress}
                    onChange={handleAddressChange}
                    placeholder="Enter your address"
                    className={`form-input text-[#202020] placeholder-[#ABB5B5] px-4 py-2 rounded-md border ${
                      showError ? "border-red-500" : "border-gray-300"
                    } w-full`}
                  />
                </div>
              </div>
              <CoverCard
                selectedCover={customerForm.cover}
                handleSelectCover={handleSelectCover}
              />
              <FullhouseCover
                customerForm={customerForm}
                setCustomerForm={setCustomerForm}
              />
            </div>
          </div>
          <div className="w-full md:w-[40%]">
            <div className="flex justify-start mb-4 md:mb-0 md:justify-end">
              <div className="flex flex-col gap-4">
                <div className="bg-[#2B87C8] w-full md:w-[320px] text-white shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                  <div>
                    <h4 className="text-[16px]">Landlord Protection</h4>
                    <p className="text-[14px] font-light">Value Plus</p>
                  </div>
                  <hr className="my-4" />
                  <div className="flex items-center gap-4 cursor-pointer">
                    <h4 className="text-[16px]">
                      Product Disclosure Statement
                    </h4>
                    <MdArrowOutward className="w-5 h-5" />
                  </div>
                  <hr className="my-4" />
                  <div>
                    <h4 className="text-[16px]">Estimated Premium</h4>
                    <h5 className="text-[32px] font-semibold pb-2">
                      {"$361.98"}
                    </h5>
                    <p className="text-[12px] font-light">
                      {"(Including GST and Government Charges)"}
                    </p>
                  </div>
                </div>
                <div className="bg-white w-full md:w-[320px] text-[#576263] border border-[#CDD4D4] shadow-lg shadow-[#CDD4D4] p-5 rounded-xl">
                  <div className="mb-6">
                    <h4 className="text-[16px] font-semibold">
                      Property Address
                    </h4>
                    <p className="text-[14px] font-light">
                      {address || "Not specified."}
                    </p>
                  </div>
                  <div className="mb-6">
                    <h4 className="text-[16px] font-semibold">
                      Period of Insurance
                    </h4>
                    <p className="text-[14px] font-light">
                      {startDate && endDate
                        ? `${startDate} - ${endDate}`
                        : "Not specified."}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-[16px] font-semibold">Quote Ref</h4>
                    <p className="text-[14px] font-light">Q-588820</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-end mt-4">
          <button
            onClick={decrementStep}
            className="flex bg-[#657375] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
          >
            <FaArrowLeft className="text-[14px]" />
            Previous
          </button>
          <button
            onClick={() => {
              if (customerForm.insured_property_adress) {
                incrementStep();
              } else {
                alert("Please fill up the property address field.");
                setShowError(true);
              }
            }}
            className="flex bg-[#2B87C8] text-white text-[16px] items-center gap-2 py-3 px-7 rounded-[3rem]"
          >
            Next
            <FaArrowRight className="text-[14px]" />
          </button>
        </div>
      </div>
    </div>
  );
}
