import React from "react";
import { IoCheckmark } from "react-icons/io5";

function StepIcon({ index, step, icon: Icon }) {
  return index < step ? (
    <IoCheckmark className="h-6 w-6" />
  ) : (
    <Icon className="h-6 w-6" />
  );
}

export default function StepperComponent({ steps, currentStep }) {
  return (
    <div className="flex justify-between items-center mt-20 md:mt-16 mb-6">
      {steps.map((step, index) => (
        <React.Fragment key={step.name}>
          {/* Line before the circle */}
          {index !== 0 && (
            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                index <= currentStep ? "border-[#2B87C8]" : "border-gray-300"
              }`}
            />
          )}
          {/* Step circle */}
          <div
            className={`flex items-center justify-center relative ${
              index === currentStep ? "z-10" : ""
            }`}
          >
            <div
              className={`rounded-full h-11 w-11 flex items-center justify-center ${
                index <= currentStep
                  ? "bg-[#2B87C8] text-white"
                  : "bg-white text-gray-500"
              } border-2`}
            >
              {/* Icon */}
              <StepIcon index={index} step={currentStep} icon={step.icon} />
            </div>
            {/* Label above the circle */}
            <span className="absolute bottom-full mb-2 text-[#202020] text-sm text-center lg:w-40 max-w-40">
              {step.name}
            </span>
          </div>
          {/* Line after the circle */}
          {index !== steps.length - 1 && (
            <div
              className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                index < currentStep ? "border-[#2B87C8]" : "border-gray-300"
              }`}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
