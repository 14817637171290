export const propertyTypes = [
  "Residential",
  "Commercial",
  "Industrial",
  "Raw Land",
  "Real Estate Investment Trust",
];

export const insurerList = [
  "QBE Insurance Group Limited",
  "Suncorp Group Limited",
  "Insurance Australia Group Limited",
  "Steadfast Group Limited",
];

export const externalWalls = [
  "Concrete",
  "Asbestos",
  "Hollow Bricks",
  "Wood",
  "Vinyl",
  "Glass",
];

export const roofTypes = [
  "Flat",
  "Terracotta tile",
  "Metal",
  "Slate",
  "Gabled",
  "Hipped",
];
